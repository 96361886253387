<template>
    <v-app id="appAdmin">
        <Carregando />

        <Snackbar />

        <v-navigation-drawer v-model="drawer" width="230" app fixed>
            <v-list-item class="px-2">
                <v-badge bordered bottom color="green accent-4" dot offset-x="25" offset-y="15">
                    <v-list-item-avatar>
                        <img :src="avatar" />
                    </v-list-item-avatar>
                </v-badge>

                <v-list-item-title>
                    <span class="text-title font-weight-medium"> {{ nomeUsuario }}</span>

                    <v-spacer></v-spacer>

                    <span class="text-sm-caption">{{ cargo }}</span>
                </v-list-item-title>
            </v-list-item>

            <v-divider></v-divider>

            <v-sheet class="pa-3 blue-grey white--text">
                <v-text-field
                    dense
                    v-model="menuBusca"
                    label="Buscar no menu..."
                    dark
                    inverted
                    hide-details
                    clearable
                ></v-text-field>
            </v-sheet>

            <v-list class="pt-5" dense>
                <template>
                    <v-treeview
                        v-model="menuTree"
                        :items="menuItems"
                        :search="menuBusca"
                        :filter="menuFiltra"
                        item-key="name"
                        activatable
                        open-on-click
                        transition
                        dense
                    >
                        <template dense slot="label" slot-scope="{ item, open }">
                            <v-icon small v-if="!item.file">
                                {{ open ? 'mdi-folder-open' : 'mdi-folder' }}
                            </v-icon>
                            <v-icon small v-else>
                                {{ files[item.file] }}
                            </v-icon>
                            <router-link :to="item.link" v-if="item.link" class="text-link">
                                <span class="subtitle-2 text-wrap mb-2">
                                    {{ item.name }}
                                </span>
                            </router-link>
                            <span class="subtitle-2" v-else>
                                {{ item.name }}
                            </span>
                        </template>
                    </v-treeview>
                </template>
            </v-list>
        </v-navigation-drawer>

        <v-app-bar app fixed dense color="tnlogLaranja" class="white--text">
            <v-app-bar-nav-icon @click="drawer = !drawer" class="white--text"></v-app-bar-nav-icon>

            <v-toolbar-title>{{ barraTitulo }}</v-toolbar-title>

            <v-spacer></v-spacer>

            <MotoristaPainel />

            <RamalDialog />

            <v-icon color="white lighten-2">
                mdi-magnify
            </v-icon>

            <strong class="pa-2">Olá, {{ primeiroNome }}</strong>

            <v-menu
                v-model="menuPerfil"
                :close-on-content-click="false"
                :nudge-width="200"
                offset-x
                offset-y
                origin="top right"
                transition="scale-transition"
            >
                <template v-slot:activator="{ on, attrs }">
                    <v-avatar v-bind="attrs" v-on="on" size="35" class="mr-1 white--text">
                        <img :src="avatar" />
                    </v-avatar>
                </template>

                <v-card>
                    <v-list dense>
                        <v-list-item>
                            <v-badge bordered bottom color="green accent-4" dot offset-x="13" offset-y="15">
                                <v-avatar class="mr-1">
                                    <img :src="avatar" />
                                </v-avatar>
                            </v-badge>

                            <v-list-item-content>
                                <v-list-item-title>
                                    <span class="text-md-body-2 font-weight-bold">
                                        {{ nomeUsuario }}
                                    </span>

                                    <v-spacer></v-spacer>

                                    <span class="text-sm-caption">{{ cargo }}</span>
                                </v-list-item-title>
                                <v-list-item-subtitle> </v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>

                    <v-divider></v-divider>

                    <v-card-actions style="display: block">
                        <v-list>
                            <v-list-item-group color="blue-grey">
                                <v-list-item>
                                    <v-icon>
                                        mdi-account-circle-outline
                                    </v-icon>
                                    <v-list-item-content @click="perfil">
                                        <v-list-item-title
                                            class="
                                                font-weight-light
                                                subtitle-1
                                                pl-3
                                            "
                                            >Perfil</v-list-item-title
                                        >
                                    </v-list-item-content>
                                </v-list-item>

                                <v-divider></v-divider>

                                <v-list-item>
                                    <v-icon> mdi-cog-outline </v-icon>
                                    <v-list-item-content>
                                        <v-list-item-title
                                            class="
                                                font-weight-light
                                                subtitle-1
                                                pl-3
                                            "
                                            >Configurações</v-list-item-title
                                        >
                                    </v-list-item-content>
                                </v-list-item>

                                <v-list-item>
                                    <v-icon> mdi-head-question-outline </v-icon>
                                    <v-list-item-content>
                                        <v-list-item-title
                                            class="
                                                font-weight-light
                                                subtitle-1
                                                pl-3
                                            "
                                            >FAQ</v-list-item-title
                                        >
                                    </v-list-item-content>
                                </v-list-item>

                                <v-divider></v-divider>

                                <v-list-item @click="sair">
                                    <v-icon> mdi-login </v-icon>
                                    <v-list-item-content>
                                        <v-list-item-title
                                            class="
                                                font-weight-light
                                                subtitle-1
                                                pl-3
                                            "
                                            >Sair</v-list-item-title
                                        >
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list-item-group>
                        </v-list>
                    </v-card-actions>
                </v-card>
            </v-menu>
        </v-app-bar>

        <div style="padding-top: 40px">
            <v-main id="scrolling-techniques" style="min-height: 90vh; padding-top: 0px" class="grey lighten-4">
                <v-container fluid>
                    <transition name="fade">
                        <router-view></router-view>
                    </transition>
                </v-container>
            </v-main>
        </div>

        <v-footer color="tnlogLaranja" class="white--text">
            <span class="ml-auto overline">{{ copyright }}</span>
        </v-footer>

        <div class="text-center">
            <v-snackbar
                v-model="notificacao.mostra"
                :multi-line="true"
                outlined
                :color="notificacao.tipo"
                timeout="3000"
                elevation="10"
                text
            >
                {{ notificacao.mensagem }}

                <template v-slot:action="{ attrs }">
                    <v-btn color="red" text v-bind="attrs" @click="notificacao.mostra = false">
                        Fechar
                    </v-btn>
                </template>
            </v-snackbar>
        </div>
    </v-app>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex';

import Echo from 'laravel-echo';

import { RemoverAcentos } from '@/helpers/Funcoes.js';
import Carregando from '@/components/Carregando.vue';
import Snackbar from '@/components/Default/Snackbar.vue';
import RamalDialog from '@/components/ListaRamal/RamalDialog.vue';
import MotoristaPainel from '@/Modulo/MotoristaPainel/Componente/Painel.vue';

export default {
    name: 'admin',
    components: {
        Carregando,
        Snackbar,
        RamalDialog,
        MotoristaPainel
    },
    mounted() {
        window.Echo.channel('event-test')
	     .listen('EventoTest', (event) => {
                // Aqui você pode fazer o console.log para testar
        	console.log('Evento recebido:', event);
	     });

    },
    async created() {
        let self = this;

        window.Pusher = require('pusher-js');
        //window.Pusher.logToConsole = true;

        window.Echo = new Echo({
            broadcaster: 'pusher',
            key: process.env.VUE_APP_TNSYS_PUSHER_APP_KEY,
            wsHost: process.env.VUE_APP_WEBSOCKET_URL,
            wsPort: process.env.VUE_APP_TNSYS_PUSHER_HTTP_PORT,
            wssPort: process.env.VUE_APP_TNSYS_PUSHER_HTTP_PORT,
            cluster: process.env.VUE_APP_TNSYS_PUSHER_APP_CLUSTER,
            //keyPrefix: "laravel_database_"
            forceTLS: false,
            encrypted: true,
            // enableLogging: true,
            disableStats: true,
            //enableStats: true,
            enabledTransports: ['ws', 'wss']
        });

        window.Echo.connector.pusher.connection.bind('connected', () => {
            this.broadcastAtualizaEstadoConexao(true);

            console.log('conectou');
            
        });

        window.Echo.connector.pusher.connection.bind('disconnected', () => {
            this.broadcastAtualizaEstadoConexao(false);
            console.log('nao conectou');
            
        });

        window.Echo.connector.pusher.connection.bind('state_change', function(states) {
            //console.log('state_change', states);
            console.log('bosta');
            if (states.current === 'disconnected') {
                //window.Echo.connector.pusher.connect();
            }

            if (states.current === 'failed') {
                //window.Echo.connector.pusher.connect();
            }
        });

        window.Echo.connector.pusher.connection.bind('reconnecting', () => {
            //console.log('reconnecting');
        });

        window.Echo.connector.pusher.connection.bind('unavailable', () => {
            this.broadcastAtualizaEstadoConexao(false);

            //window.Echo.connector.pusher.connect();

            //console.log('unavailable');
        });

        window.Echo.connector.pusher.connection.bind('failed', () => {
            this.broadcastAtualizaEstadoConexao(false);

            console.log('falhou');
            
        });

        await self.$store
            .dispatch('autenticacao/verificaAutenticacao')
            .then(respostaApi => {
                if (self.$route.name == 'login' || self.$route.name == null) {
                    self.$router.push({ name: 'admin' });
                }
            })
            .catch(error => {
                if (self.$route.name != 'login') {
                    self.$router.push({ name: 'login' });
                }
            });
    },
    data: () => ({
        notificacao: {
            mostra: false,
            mensagem: '',
            tipo: 'success'
        },
        barraTitulo: process.env.VUE_APP_BARRA_TITULO,
        copyright: process.env.VUE_APP_COPYRIGHT,
        menuPerfil: false,
        menuBusca: null,
        drawer: null,
        files: {
            html: 'mdi-language-html5',
            js: 'mdi-nodejs',
            json: 'mdi-code-json',
            md: 'mdi-language-markdown',
            pdf: 'mdi-file-pdf',
            png: 'mdi-file-image',
            txt: 'mdi-file-document-outline',
            xls: 'mdi-file-excel',
            file: 'mdi-file',
            account: 'mdi-account-switch',
            dashboard: 'mdi-monitor-dashboard',
            truck: 'mdi-truck',
            'truck-delivery': 'mdi-truck-delivery',
            person: 'mdi-account-tie',
            phone: 'mdi-phone-classic',
            notice: 'mdi-note',
            office: 'mdi-offer',
            mdiSecurity: 'mdi-security',
            company: 'mdi-office-building',
            warehouse: 'mdi-warehouse',
            at: 'mdi-at',
            trainContainer: 'mdi-train-car-container',
            clipboardOutline: 'mdi-clipboard-list-outline',
            whatsapp: 'mdi-whatsapp',
            ticketAccount: 'mdi-ticket-account',
            'package-variant-closed': 'mdi-package-variant-closed',
            clipboardOutline: 'mdi-clipboard-list-outline',
            fileChartOutlined: 'mdi-file-chart-outline',
            accountGroup: 'mdi-account-group',
            faceAgent: 'mdi-face-agent',
            sitemap: 'mdi-sitemap',
            fileChartOutlined: 'mdi-file-chart-outline',
            chartLineVariant: 'mdi-chart-line-variant',
            chartAreasplineVariant: 'mdi-chart-areaspline-variant',
            badgeAccountAlertOutline: 'mdi-badge-account-alert-outline',
            mdiAccountBadgeOutline: 'mdi-account-badge-outline',
            mdiTextAccount: 'mdi-text-account',
            mdiFileSign: 'mdi-file-sign'
        },
        menuTree: [],
        menuItems: [
            {
                name: 'Dashboard',
                file: 'dashboard',
                link: '/admin/dashboard'
            },
            {
                name: 'Atendimento',
                children: [
                    {
                        name: 'Atendente Whatsapp',
                        file: 'mdiAccountBadgeOutline',
                        link: '/admin/whatsapp-atendente'
                    },
                    {
                        name: 'Fluxo Whatsapp',
                        file: 'sitemap',
                        link: '/admin/whatsapp-fluxo'
                    },
                    {
                        name: 'Departamento Whatsapp',
                        file: 'accountGroup',
                        link: '/admin/whatsapp-departamento'
                    },
                    {
                        name: 'Whatsapp Atendimento',
                        file: 'faceAgent',
                        link: '/admin/whatsapp/atendimento'
                    },
                    {
                        name: 'Whatsapp Chamado',
                        file: 'ticketAccount',
                        link: '/admin/whatsapp/atendimento-fluxo'
                    },
                    {
                        name: 'Whatsapp Marketing',
                        file: 'mdiFileSign',
                        link: '/admin/whatsapp-marketing'
                    }
                ]
            },
            {
                name: 'Cadastros',
                children: [
                    {
                        name: 'CID',
                        file: 'office',
                        link: '/admin/atestado-cid'
                    },
                    {
                        name: 'Motivo do/ Atestado',
                        file: 'office',
                        link: '/admin/atestado-motivo'
                    },
                    {
                        name: 'Cargo',
                        file: 'office',
                        link: '/admin/cargo'
                    },
                    {
                        name: 'Colaborador',
                        file: 'person',
                        link: '/admin/colaborador'
                    },
                    {
                        name: 'Departamento',
                        file: 'office',
                        link: '/admin/Departamento'
                    },
                    {
                        name: 'Lista Ramal',
                        file: 'phone',
                        link: '/admin/lista-ramal'
                    },
                    {
                        name: 'Noticia',
                        file: 'notice',
                        link: '/admin/noticia'
                    },
                    {
                        name: 'Permissão',
                        file: 'office',
                        link: '/admin/permissao'
                    },
                    {
                        name: 'Modulos',
                        file: 'office',
                        link: '/admin/modulo'
                    },
                    {
                        name: 'Perfis de Acesso',
                        file: 'mdiSecurity',
                        link: '/admin/usuario-perfil'
                    },
                    {
                        name: 'Sindicato',
                        file: 'office',
                        link: '/admin/sindicato'
                    },
                    {
                        name: 'Tipo de Contratação',
                        file: 'office',
                        link: '/admin/tipo-contratacao'
                    },
                    {
                        name: 'Tracking E-mail',
                        file: 'at',
                        link: '/admin/cadastros/tracking-email'
                    },
                    {
                        name: 'Unidade de Empreendimento',
                        file: 'company',
                        link: '/admin/unidade-empreendimento'
                    },
                    {
                        name: 'Usuário',
                        file: 'company',
                        link: '/admin/usuario'
                    }
                ]
            },
            {
                name: 'Comercial',
                children: [
                    {
                        name: 'Markup',
                        file: 'txt',
                        link: '/admin/comercial/profit-less-markup'
                    }
                ]
            },
            {
                name: 'Financeiro',
                children: [
                    {
                        name: 'Fluxo de Caixa',
                        file: 'fileChartOutlined',
                        link: '/admin/financeiro/fluxo-caixa'
                    },
                    {
                        name: 'P&L Statement',
                        file: 'chartAreasplineVariant',
                        link: '/admin/financeiro/profit-less/statement'
                    },
                    {
                        name: 'Painel Faturamento',
                        file: 'badgeAccountAlertOutline',
                        link: '/admin/financeiro/painel-faturamento'
                    }
                ]
            },
            {
                name: 'Monitoramento',
                children: [
                    {
                        name: 'Motorista',
                        children: [
                            {
                                name: 'Jornada',
                                children: [
                                    {
                                        name: 'Completo',
                                        file: 'mdiTextAccount',
                                        link: '/admin/monitoramento/motorista/jornada-motorista'
                                    },
                                    {
                                        name: 'Início Viagem',
                                        file: 'mdiTextAccount',
                                        link: '/admin/monitoramento/motorista/jornada-inicio-viagem'
                                    },
                                    {
                                        name: 'Parada Espera',
                                        file: 'mdiTextAccount',
                                        link: '/admin/monitoramento/motorista/jornada-parada-espera'
                                    },
                                    {
                                        name: 'Fim Viagem',
                                        file: 'mdiTextAccount',
                                        link: '/admin/monitoramento/motorista/jornada-fim-viagem'
                                    }
                                ]
                            }
                        ]
                    }
                ]
            },
            {
                name: 'Programação Carga',
                children: [
                    {
                        name: 'Demandas',
                        file: 'truck',
                        link: '/admin/alocacao-carga/demanda'
                    },
                    {
                        name: 'Programados',
                        file: 'warehouse',
                        link: '/admin/alocacao-carga/romaneios'
                    },
                    {
                        name: 'Painel de Entregas',
                        file: 'truck-delivery',
                        link: '/admin/alocacao-carga/painel-entrega'
                    },
                    {
                        name: 'Romaneios Disponíveis',
                        file: 'package-variant-closed',
                        link: '/admin/alocacao-carga/romaneios-disponiveis'
                    },
                    {
                        name: 'Devolução Vazio',
                        file: 'trainContainer',
                        link: '/admin/alocacao-carga/devolucao-vazio'
                    },
                    {
                        name: 'Devolução Vazio Auditoria Processo',
                        file: 'trainContainer',
                        link: '/admin/alocacao-carga/devolucao-vazio/auditoria-processo'
                    },
                    {
                        name: 'Painel de Auditoria CTe',
                        file: 'badgeAccountAlertOutline',
                        link: '/admin/alocacao-carga/cte-painel-auditoria'
                    }
                ]
            },
            {
                name: 'RH',
                children: [
                    {
                        name: 'Avaliação Desempenho',
                        file: 'clipboardOutline',
                        link: '/admin/rh/avaliacao-desempenho/avaliacao'
                    },
                    {
                        name: 'Respostas',
                        file: 'file',
                        link: '/admin/rh/avaliacao-desempenho/resposta'
                    },
                    {
                        name: 'PDI',
                        file: 'account',
                        link: '/admin/rh/plano-desenvolvimento-individual'
                    }
                ]
            },
            {
                name: 'Relatórios',
                children: [
                    {
                        name: 'Alocação de Carga',
                        children: [
                            {
                                name: 'Documento Pronto',
                                file: 'txt',
                                link: '/admin/relatorio/alocacao-carga/documento-pronto'
                            }
                        ]
                    },
                    {
                        name: 'Programação de Carga',
                        children: [
                            {
                                name: 'Order to Delivery',
                                file: 'txt',
                                link: '/admin/relatorio/programacao-carga/order-to-delivery'
                            }
                        ]
                    },
                    {
                        name: 'RH',
                        children: [
                            {
                                name: 'Funcionário',
                                file: 'txt',
                                link: '/admin/relatorio/rh/funcionario'
                            }
                        ]
                    }
                ]
            },
            /*{
				name: 'Comex',
				children: [
					{
						name: 'Incoterm',
						file: 'txt',
						link: '/admin/noticia2',
					},
					{
						name: 'Processo de Exportação',
						file: 'txt',
						link: '/admin/noticia2',
					},
					{
						name: 'Processo de Importação',
						file: 'txt',
						link: '/admin/noticia2',
					},
				],
			},*/
            /*{
				name: 'Compras',
				children: [
					{
						name: 'Gráfico',
						children: [
							{
								name: 'Vendas por Estado',
								file: 'png',
								link: '/admin/noticia2',
							},
						],
					},
					{
						name: 'Analítico',
						children: [
							{
								name: 'Faturamento detalhado',
								file: 'png',
								link: '/admin/noticia2',
							},
						],
					},
				],
			},*/
            /*{
				name: 'Estoque',
				children: [
					{
						name: 'Consulta Estoque',
						file: 'txt',
						link: '/admin/noticia2',
					},
					{
						name: 'Estoque por Endereço',
						file: 'txt',
						link: '/admin/noticia2',
					},
					{
						name: 'Reserva por Endereço',
						file: 'txt',
						link: '/admin/noticia2',
					},
					{
						name: 'Kardex',
						file: 'txt',
						link: '/admin/noticia2',
					},
				],
			},*/
            /*{
				name: 'Expedição',
				children: [
					{
						name: 'Picking Conferrência',
						file: 'txt',
						link: '/admin/noticia2',
					},
					{
						name: 'Faturamento',
						file: 'txt',
						link: '/admin/noticia2',
					},
				],
			},*/
            /*{
				name: 'Fiscal',
				children: [
					{
						name: 'Nota Entrada',
						file: 'txt',
						link: '/admin/noticia2',
					},
					{
						name: 'Recebimento Físico',
						file: 'txt',
						link: '/admin/noticia2',
					},
				],
			},*/
            /*{
				name: 'Financeiro',
				children: [
					{
						name: 'Contas a Pagar',
						file: 'txt',
						link: '/admin/noticia2',
					},
					{
						name: 'Contas a Receber',
						file: 'txt',
						link: '/admin/noticia2',
					},
					{
						name: 'Despesa',
						file: 'txt',
						link: '/admin/noticia2',
					},
					{
						name: 'Gera Remessa',
						file: 'txt',
						link: '/admin/noticia2',
					},
					{
						name: 'Plafin',
						file: 'txt',
						link: '/admin/noticia2',
					},
				],
			},*/
            /*{
				name: 'Vendas',
				children: [
					{
						name: 'Gráfico',
						children: [
							{
								name: 'Vendas por Estado',
								file: 'png',
								link: '/admin/noticia2',
							},
						],
					},
					{
						name: 'Analítico',
						children: [
							{
								name: 'Faturamento detalhado',
								file: 'png',
								link: '/admin/noticia2',
							},
						],
					},
				],
			},*/
        ]
    }),
    computed: {
        ...mapGetters({
            nomeUsuario: 'autenticacao/getNomeCompleto',
            cargo: 'autenticacao/getCargo',
            avatar: 'autenticacao/getAvatar'
        }),
        menuFiltra() {
            return this.menuBusca
                ? (item, search, textKey) => {
                      let textoSemAcento = RemoverAcentos(item[textKey]).toLowerCase();
                      let textoBuscaSemAcento = RemoverAcentos(search).toLowerCase();

                      return textoSemAcento.indexOf(textoBuscaSemAcento) > -1;
                  }
                : undefined;
        },
        primeiroNome() {
            return this.nomeUsuario.split(' ')[0];
        }
    },
    methods: {
        ...mapActions({
            broadcastAtualizaEstadoConexao: 'broadcast/atualizaEstadoConexao'
        }),
        async sair() {
            try {
                await this.$store
                    .dispatch('autenticacao/sai')
                    .then(resposta => {
                        this.$router.push({ name: 'login' });
                    })
                    .catch(erro => {
                        throw new Error(erro.message);
                    });
            } catch (e) {
                this.$store.commit('setSnack', {
                    show: true,
                    message: e.message
                });
            }
        },
        async perfil() {
            try {
                if (this.$route.path !== '/admin/perfil-usuario') {
                    this.$router.push({ name: 'perfil-usuario' });
                }
            } catch (e) {
                this.$store.commit('setSnack', {
                    show: true,
                    message: e.message
                });
            }
        }
    }
};
</script>

<style scoped>
.text-link {
    color: inherit;
    text-decoration: inherit;
    cursor: pointer;
}
/* width */
.v-navigation-drawer > ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

/* Track */
.v-navigation-drawer > ::-webkit-scrollbar-track {
    background: #ffffff;
}

/* Handle */
.v-navigation-drawer > ::-webkit-scrollbar-thumb {
    background: rgb(225, 162, 135);
}

/* Handle on hover */
.v-navigation-drawer > ::-webkit-scrollbar-thumb:hover {
    background: #eb7405;
}
</style>

<style>
.v-treeview-node__content {
    margin-left: 0px !important;
}
</style>
